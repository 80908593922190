import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AppConfig } from './app.config';
import { annotate } from 'rough-notation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    protected isPhonePortrait = false;
    protected currentRegisteredContactName = '...';
    protected currentRegisteredContactNameNumOfInvitation = 0;
    protected registrationStatus: 'not-yet' | 'success' | 'failed' = 'not-yet';

    private static readonly SECTION_VISIBLE_PX_THRESHOLD = 60;
    private readonly contactList: [{ contactName: string; phoneNumber: string; numOfInvitation: number }];
    private readonly contactTarget: { contactName: string; phoneNumber: string };

    constructor(private responsive: BreakpointObserver) {
        this.contactList = JSON.parse(JSON.parse(atob(AppConfig.CONTACT_LIST_BASE64_ENCODED)));
        this.contactTarget = JSON.parse(JSON.parse(atob(AppConfig.CONTACT_TARGET_BASE64_ENCODED)));

        window.addEventListener('scroll', () => {
            const sections = document.querySelectorAll('.animating-section');

            for (let i = 0; i < sections.length; i++) {
                const windowHeight = window.innerHeight;
                const elementTop = sections[i].getBoundingClientRect().top;

                if (elementTop < windowHeight - AppComponent.SECTION_VISIBLE_PX_THRESHOLD) {
                    sections[i].classList.add('animate');
                }

                if (
                    sections[i].className == 'attendance-section animating-section animate' &&
                    sections[i].getAttribute('hasShowedOnce') !== 'true'
                ) {
                    sections[i].setAttribute('hasShowedOnce', 'true');

                    for (let i = 1; i <= 2; i++) {
                        setTimeout(
                            () => {
                                const e = document.getElementById('contact-invitation-count') as HTMLElement;

                                if (e) {
                                    const annotation = annotate(e, { type: 'underline', animationDuration: 2000 });
                                    annotation.show();
                                }
                            },
                            1000 + i * 2000,
                        );
                    }
                }

                if (
                    sections[i].className == 'mv-one-section animating-section animate' &&
                    sections[i].getAttribute('hasShowedOnce') !== 'true'
                ) {
                    sections[i].setAttribute('hasShowedOnce', 'true');

                    for (let i = 1; i <= 4; i++) {
                        setTimeout(
                            () => {
                                const e = document.getElementById(`mv-one-text-${i}`) as HTMLElement;

                                if (e) {
                                    const annotation = annotate(e, {
                                        type: 'highlight',
                                        color: '#83807bbf',
                                        animationDuration: 2000,
                                    });
                                    annotation.show();
                                }
                            },
                            1000 + i * 2000,
                        );
                    }
                }

                if (
                    sections[i].className == 'mv-two-section animating-section animate' &&
                    sections[i].getAttribute('hasShowedOnce') !== 'true'
                ) {
                    sections[i].setAttribute('hasShowedOnce', 'true');

                    for (let i = 1; i <= 7; i++) {
                        setTimeout(
                            () => {
                                const e = document.getElementById(`mv-two-text-${i}`) as HTMLElement;
                                let blur = 10;
                                setInterval(() => {
                                    e.style.filter = `blur(${blur--}px)`;
                                }, 150);
                            },
                            1000 + i * 2000,
                        );
                    }
                }
            }
        });
    }

    ngOnInit(): void {
        this.responsive.observe(Breakpoints.HandsetPortrait).subscribe((result) => {
            this.isPhonePortrait = result.matches;
        });
    }

    protected onRegistrationButtonSubmitted(): void {
        const phoneNumber = document.getElementById('registration-phone-number') as HTMLInputElement;

        // ignore empty input
        if (!phoneNumber.value) {
            return;
        }

        const contact = this.contactList.find((contact) => contact.phoneNumber === phoneNumber.value);

        if (!contact) {
            this.registrationStatus = 'failed';
            return;
        }

        this.registrationStatus = 'success';
        this.currentRegisteredContactName = contact.contactName;
        this.currentRegisteredContactNameNumOfInvitation = contact.numOfInvitation;
    }

    protected onAttendance(isAttending: boolean): void {
        let chatMessage = `Ya, hadir ${this.currentRegisteredContactNameNumOfInvitation} Dewasa`;

        if (!isAttending) {
            chatMessage = 'Tidak hadir';
        }

        const redirectUrl = encodeURI(`https://wa.me/${this.contactTarget.phoneNumber}?text=${chatMessage}`);
        window.open(redirectUrl, '_blank');
    }
}
