<div *ngIf="this.registrationStatus == 'failed'">
    <div class="registration-background-container" style="filter: brightness(0.4)">
        <div *ngIf="this.isPhonePortrait">
            <img class="registration-background-image-portrait" src="assets/images/registration_potrait.jpg" />
        </div>

        <div *ngIf="!this.isPhonePortrait">
            <img class="registration-background-image-landscape" src="assets/images/registration_landscape.jpg" />
        </div>
    </div>
    <div class="registration-failed-content-container">
        <div
            class="secondary-font-color"
            style="
                text-align: center;
                width: 80%;
                font-family: 'kunstler-script', 'pinyon-script', 'Brush Script MT', 'cursive';
                font-size: 15vh;
            "
        >
            Sorry
        </div>
        <div class="secondary-font-color" style="margin-top: 2vw; text-align: center; width: 80%">
            We couldn’t find your phone number. <br />Please contact our Wedding Organizer
            <br *ngIf="this.isPhonePortrait" />or family for further information. <br />Thank you!
        </div>
    </div>
</div>
<div *ngIf="this.registrationStatus == 'not-yet'">
    <div class="registration-section">
        <div class="registration-background-container">
            <div *ngIf="this.isPhonePortrait">
                <img class="registration-background-image-portrait" src="assets/images/registration_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img class="registration-background-image-landscape" src="assets/images/registration_landscape.jpg" />
            </div>
        </div>
        <div class="registration-content-container">
            <div class="registration-content">
                <div class="registration-heading secondary-font-color">Hello!</div>

                <div class="registration-subheading secondary-font-color">Please input your phone number</div>

                <div class="registration-form-container">
                    <input
                        type="number"
                        class="registration-form-input"
                        id="registration-phone-number"
                        placeholder="0818xxxxx"
                    />
                </div>

                <button class="registration-form-submit-button" (click)="this.onRegistrationButtonSubmitted()">
                    SUBMIT
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="this.registrationStatus == 'success'">
    <div class="wedding-of-section animating-section">
        <div class="wedding-of-background-container" style="animation: blur-out 2s forwards ease-in">
            <div *ngIf="this.isPhonePortrait">
                <img class="wedding-of-background-image-portrait" src="assets/images/wedding_of_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img class="wedding-of-background-image-landscape" src="assets/images/wedding_of_landscape.jpg" />
            </div>
        </div>

        <div class="wedding-of-content-container" style="animation: fade-in 3s forwards ease-in">
            <div class="wedding-of-content">
                <div class="wedding-of-subheading primary-font-color">WEDDING OF</div>
                <div
                    *ngIf="this.isPhonePortrait"
                    class="primary-font-color"
                    style="
                        font-family: 'pinyon-script', 'Brush Script MT', 'cursive';
                        font-size: 8vh;
                        line-height: 6vh;
                        margin-top: 5vh;
                    "
                >
                    <div>Danny</div>
                    <div style="text-align: center; margin-left: 8vw">&</div>
                    <div style="text-align: right">Alex</div>
                </div>
                <div *ngIf="!this.isPhonePortrait" class="primary-font-color" style="text-align: center">
                    <div
                        style="
                            font-family: 'pinyon-script', 'Brush Script MT', 'cursive';
                            font-size: 8vh;
                            line-height: 6vh;
                            margin-top: 3vh;
                        "
                    >
                        Danny & Alex
                    </div>
                    <div style="font-size: 2vh; margin-top: 2vw">20.04.2024</div>
                </div>
            </div>
        </div>
    </div>

    <div class="danny-section animating-section">
        <div class="danny-background-container">
            <div *ngIf="this.isPhonePortrait">
                <img class="danny-background-image-portrait" src="assets/images/danny_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img class="danny-background-image-landscape" src="assets/images/danny_landscape.jpg" />
            </div>
        </div>

        <div class="danny-content-container">
            <div class="danny-content">
                <div class="danny-content-background">
                    <img class="danny-gani-name-image" src="assets/images/danny_gani_name.png" />
                    <div class="danny-subheading secondary-font-color">The first son of</div>
                    <div class="danny-subheading secondary-font-color">Mr. Tommy Gani</div>
                    <div class="danny-subheading secondary-font-color">& Mrs. Lina</div>
                    <br />
                    <br />
                    <div class="danny-subheading secondary-font-color">The brother of</div>
                    <div class="danny-subheading secondary-font-color">Kenny Gani</div>
                </div>
            </div>
        </div>
    </div>

    <div class="michele-section animating-section">
        <div class="michele-background-container">
            <div *ngIf="this.isPhonePortrait">
                <img class="michele-background-image-portrait" src="assets/images/michele_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img class="michele-background-image-landscape" src="assets/images/michele_landscape.jpg" />
            </div>
        </div>

        <div class="michele-content-container">
            <div class="michele-content">
                <div class="michele-content-background">
                    <img class="michele-name-image" src="assets/images/michele_alexander_name.png" />
                    <div class="michele-subheading primary-font-color">The first daughter of</div>
                    <div class="michele-subheading primary-font-color">Mr. Sukardi</div>
                    <div class="michele-subheading primary-font-color">& Mrs. F. R. Ilonny Sunur</div>
                    <br />
                    <br />
                    <div class="danny-subheading primary-font-color">The sister of</div>
                    <div class="danny-subheading primary-font-color">Leonard Archie</div>
                    <div class="danny-subheading primary-font-color">Dylan Archie</div>
                </div>
            </div>
        </div>
    </div>

    <div class="holy-matrimony-section animating-section">
        <div class="holy-matrimony-background-container">
            <div *ngIf="this.isPhonePortrait">
                <img class="holy-matrimony-background-image-portrait" src="assets/images/holy_matrimony_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img
                    class="holy-matrimony-background-image-landscape"
                    src="assets/images/holy_matrimony_landscape.jpg"
                />
            </div>
        </div>

        <div class="holy-matrimony-content-container">
            <div class="holy-matrimony-content">
                <div class="holy-matrimony-content-top">
                    <div
                        class="holy-matrimony-subheading-large secondary-font-color"
                        style="
                            font-style: italic;
                            font-family: 'apothicaire-light', 'Courier New', 'Courier, monospace';
                        "
                    >
                        Holy Matrimony
                    </div>
                    <div class="holy-matrimony-subheading-medium secondary-font-color">
                        Saturday, 20th April 2024 | 09.00 am
                    </div>
                    <div
                        class="holy-matrimony-subheading-small secondary-font-color holy-matrimony-emphasis-text-animation"
                        style="text-align: center"
                    >
                        <a href="https://maps.app.goo.gl/pjNdhWrjzLTyG2R2A" target="_blank" style="color: #fceed0"
                            >Gereja St. Ambrosius<br />
                            Villa Melati Mas Blok O6 No. 26<br />
                            Tangerang Selatan<br
                        /></a>
                    </div>
                </div>
                <div class="holy-matrimony-content-middle">
                    <div
                        class="holy-matrimony-subheading-x-large secondary-font-color"
                        style="
                            font-family: 'kunstler-script', 'pinyon-script', 'Brush Script MT', 'cursive';
                            text-align: center;
                            text-wrap: nowrap;
                        "
                    >
                        Save<br />
                        The Date
                    </div>
                </div>
                <div class="holy-matrimony-content-bottom">
                    <div
                        class="holy-matrimony-subheading-large secondary-font-color"
                        style="
                            font-style: italic;
                            font-family: 'apothicaire-light', 'Courier New', 'Courier, monospace';
                        "
                    >
                        Reception
                    </div>
                    <div class="holy-matrimony-subheading-medium secondary-font-color">
                        Saturday, 20th April 2024 | 6.30 pm
                    </div>
                    <div
                        class="holy-matrimony-subheading-small secondary-font-color holy-matrimony-emphasis-text-animation"
                        style="text-align: center"
                    >
                        <a href="https://maps.app.goo.gl/g4Noi1h4GgsdraAd8" target="_blank" style="color: #fceed0">
                            Ayana Midplaza Jakarta<br />
                            Jl. Jenderal Sudirman No. Kav 10-11 Jakarta<br />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="attendance-section animating-section">
        <div class="attendance-background-container">
            <div *ngIf="this.isPhonePortrait">
                <img class="attendance-background-image-portrait" src="assets/images/attendance_potrait.jpg" />
            </div>

            <div *ngIf="!this.isPhonePortrait">
                <img class="attendance-background-image-landscape" src="assets/images/attendance_landscape.jpg" />
            </div>
        </div>

        <div class="attendance-content-container">
            <div class="attendance-content">
                <div class="attendance-content-top">
                    <div class="attendance-subheading-medium primary-font-color">Dear, Mr/Ms/Mrs</div>
                    <div
                        class="attendance-subheading-large primary-font-color"
                        style="
                            font-family: 'pinyon-script', 'Brush Script MT', 'cursive';
                            margin-top: 2vh;
                            text-align: center;
                        "
                    >
                        {{ this.currentRegisteredContactName }}
                    </div>
                    <div
                        class="attendance-subheading-small primary-font-color"
                        style="text-align: center"
                        id="contact-invitation-count"
                    >
                        {{ this.currentRegisteredContactNameNumOfInvitation }} Invitee(s)
                    </div>
                    <div
                        class="attendance-subheading-medium primary-font-color"
                        style="text-align: center; margin-top: 2vh"
                    >
                        Let us know your attendance<br />
                    </div>
                    <div class="attendance-button-group" style="margin-top: 1vh">
                        <button class="attendance-button" (click)="this.onAttendance(true)">I'm in</button>
                        <button class="attendance-button" (click)="this.onAttendance(false)">
                            Sorry <br />
                            I can't attend
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="trailer-section animating-section">
        <iframe
            src="https://drive.google.com/file/d/18n6d0mtgJCyBN09quFSJ3ph4q41zsYXa/preview"
            allow="autoplay"
            frameBorder="0"
            scrolling="no"
            allowfullscreen
        ></iframe>
    </div>

    <div class="collage-section animating-section">
        <div class="collage-section-left">
            <div class="collage-overlay" *ngIf="this.isPhonePortrait">
                <div
                    class="collage-overlay-item secondary-font-color"
                    style="
                        font-family: 'kunstler-script', 'pinyon-script', 'Brush Script MT', 'cursive';
                        margin-top: 310%;
                    "
                >
                    Michele Alexander
                </div>
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_left_1.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_left_2.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_left_3.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
        </div>
        <div class="collage-section-right">
            <div class="collage-overlay" *ngIf="this.isPhonePortrait">
                <div
                    class="collage-overlay-item secondary-font-color"
                    style="
                        font-family: 'kunstler-script', 'pinyon-script', 'Brush Script MT', 'cursive';
                        margin-left: 78%;
                        margin-top: 200%;
                    "
                >
                    Danny Gani
                </div>
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_right_1.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_right_2.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div class="collage-item">
                <img src="assets/images/collage_right_3.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
        </div>
    </div>

    <div class="mv-one-section animating-section">
        <div style="position: relative; margin-top: -10px">
            <img src="assets/images/mv_one_one.jpg" style="width: 100%; height: auto; object-fit: cover" />
            <div
                class="secondary-font-color"
                style="position: absolute; bottom: 2vh; text-align: center; width: 100%; font-size: 3vw"
            >
                <span id="mv-one-text-1">Will it be a pavement or a sidewalk</span>
            </div>
        </div>
        <div style="position: relative; margin-top: -10px">
            <img src="assets/images/mv_one_two.jpg" style="width: 100%; height: auto; object-fit: cover" />
            <div
                class="secondary-font-color"
                style="position: absolute; bottom: 2vh; text-align: center; width: 100%; font-size: 3vw"
            >
                <span id="mv-one-text-2"> When I finally lay my eyes on you?</span>
            </div>
        </div>
        <div style="position: relative; margin-top: -10px">
            <img src="assets/images/mv_one_three.jpg" style="width: 100%; height: auto; object-fit: cover" />
            <div
                class="secondary-font-color"
                style="position: absolute; bottom: 2vh; text-align: center; width: 100%; font-size: 3vw"
            >
                <span id="mv-one-text-3"> Someone I've already loved</span>
            </div>
        </div>
        <div style="position: relative; margin-top: -10px">
            <img src="assets/images/mv_one_four.jpg" style="width: 100%; height: auto; object-fit: cover" />
            <div
                class="secondary-font-color"
                style="position: absolute; bottom: 2vh; text-align: center; width: 100%; font-size: 3vw"
            >
                <span id="mv-one-text-4"> will you find your way out of the blue?</span>
            </div>
        </div>
    </div>

    <div class="mv-two-section animating-section">
        <div style="position: relative; margin-top: 8%">
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 2%;
                    margin-top: 15%;
                    transform: rotate(10deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -3;
                    height: 45vw;
                "
            >
                <img src="assets/images/mv_two_one.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 31%;
                    margin-top: 0%;
                    transform: rotate(347deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    height: 45vw;
                "
            >
                <img src="assets/images/mv_two_two.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 63%;
                    margin-top: 20%;
                    transform: rotate(347deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    height: 39vw;
                "
            >
                <img src="assets/images/mv_two_three.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 5%;
                    margin-top: 59%;
                    transform: rotate(342deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -2;
                    height: 45vw;
                "
            >
                <img src="assets/images/mv_two_four.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 41%;
                    margin-top: 58%;
                    transform: rotate(8deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -1;
                    height: 39vw;
                "
            >
                <img src="assets/images/mv_two_five.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 66%;
                    margin-top: 81%;
                    transform: rotate(2deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -1;
                    height: 39vw;
                "
            >
                <img src="assets/images/mv_two_six.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 17%;
                    margin-top: 100%;
                    transform: rotate(10deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -1;
                    height: 42vw;
                "
            >
                <img src="assets/images/mv_two_seven.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                style="
                    position: absolute;
                    width: 30%;
                    left: 51%;
                    margin-top: 110%;
                    transform: rotate(345deg);
                    outline-color: white;
                    outline-width: 2vw;
                    outline-style: solid;
                    z-index: -3;
                    height: 45vw;
                "
            >
                <img src="assets/images/mv_two_eight.jpg" style="width: 100%; height: auto; object-fit: cover" />
            </div>
            <div
                class="secondary-font-color"
                style="
                    font-family: 'apothicaire-light', 'Courier New', 'Courier, monospace';
                    font-size: 3vw;
                    position: absolute;
                    width: 97%;
                    margin-top: 0%;
                    z-index: -3;
                    text-align: right;
                "
            >
                <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end">
                    <div id="mv-two-text-1" style="filter: blur(10px)">Maybe it's just&nbsp;</div>
                    <div
                        id="mv-two-text-2"
                        style="
                            font-family: 'pinyon-script', 'Brush Script MT', 'cursive';
                            font-size: 4vw;
                            filter: blur(10px);
                        "
                    >
                        circumstance
                    </div>
                </div>
                <span id="mv-two-text-3" style="filter: blur(10px)">Or general compatibility</span>
            </div>
            <div
                class="secondary-font-color"
                style="
                    font-family: 'apothicaire-light', 'Courier New', 'Courier, monospace';
                    font-size: 3vw;
                    position: absolute;
                    width: 100%;
                    left: 3%;
                    margin-top: 156%;
                    z-index: -3;
                "
            >
                <div id="mv-two-text-4" style="filter: blur(10px)">So here`s to you</div>
                <div style="display: flex; flex-direction: row; align-items: center">
                    <div id="mv-two-text-5" style="filter: blur(10px)">the most&nbsp;</div>
                    <div
                        id="mv-two-text-6"
                        style="
                            font-family: 'pinyon-script', 'Brush Script MT', 'cursive';
                            font-size: 4vw;
                            filter: blur(10px);
                        "
                    >
                        beautiful&nbsp;
                    </div>
                    <div id="mv-two-text-7" style="filter: blur(10px)">thing that I have never seen</div>
                </div>
            </div>
        </div>
    </div>
</div>
